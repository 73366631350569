<template>
  <ChoosePeopleModal
    paddingless
    :multiselect="true"
    :invite-people="false"
    :exclude-users="flow.user_keys ?? []"
    :endpoint="endpointSearch"
    @selected="selectedPeople"
    @close="$emit('close')"
  >
    <template #previous>
      <div class="rounded-xl bg-gray-100 px-3 py-2 my-1.5">
        <div v-if="flow.user_keys?.length">
          <fw-person v-for="user in flow.user_keys" :key="user" :person="users?.[user]"> </fw-person>
        </div>
        <div v-if="flow.user_roles?.length">
          <p v-for="role in flow.user_roles" :key="role">{{ role }}</p>
        </div>
      </div>
    </template>
  </ChoosePeopleModal>
</template>
<script>
import ChoosePeopleModal from '@/fw-modules/fw-core-vue/ui/components/modals/ChoosePeopleModal'

export default {
  components: {
    ChoosePeopleModal
  },
  props: {
    users: {
      type: Object,
      default: () => {}
    },
    flow: {
      type: Object,
      default: () => {}
    },
    endpointSearch: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      selectedUsers: []
    }
  },

  computed: {
    language() {
      return this.$i18n.locale
    }
  },

  mounted() {
    this.selectedUsers = this.flow.user_keys ?? []
  },

  methods: {
    selectedPeople(users) {
      this.$emit('flow-update', { flow: this.flow, add: { user_keys: users.map(user => user.key) } })
    }
  }
}
</script>

<i18n lang="json">
{
  "pt": {},
  "en": {}
}
</i18n>
